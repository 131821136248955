//
// Raleway fonts
//

@font-face {
  font-family: 'Raleway';
  src: url('../../../fonts/raleway/Raleway-Light.ttf') format('truetype');  
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../../../fonts/raleway/Raleway-Regular.ttf') format('truetype');  
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../../../fonts/raleway/Raleway-SemiBold.ttf') format('truetype');  
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../../../fonts/raleway/Raleway-Bold.ttf') format('truetype');  
  font-weight: 700;
  font-style: normal;
}