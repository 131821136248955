//
// tables.scss
//

//Table centered (Custom)
.table-centered {
    th,
    td {
        vertical-align: middle !important;
    }
}

// Custom table components (Custom)
.table {
    .table-user {
        img {
            height: 30px;
            width: 30px;
        }
    }
    .action-icon {
        color: $gray-600;
        font-size: 1.2rem;
        display: inline-block;
        padding: 0 3px;

        &:hover {
            color: $gray-700;
        }
    }
}

// Tables fluid
.table-nowrap {
    th,
    td {
        white-space: nowrap;
    }
}

// Data Table

table.data-table {
    thead {
        .sorting {
            padding-right: 30px;
            padding-left: 0.95rem;
            cursor: pointer;
            position: relative;
            &::before {
                right: 0.5rem;
                left: auto;
                content: "W";
                font-family: "dripicons-v2";
                font-size: 1rem;
                top: 10px;
            }
            &::after {
                right: 0.5em;
                left: auto;
                content: "T";
                font-family: "dripicons-v2";
                top: 20px;
                font-size: 1rem;
            }

            &::before,
            &::after {
                position: absolute;
                bottom: 0.5em;
                display: block;
                opacity: 0.3;
            }
        }

        .sorting_desc:after {
            opacity: 1;
        }

        .sorting_asc:before {
            opacity: 1;
        }
    }

    tr{
        &.selectable{
            cursor: pointer;
        }
    }
}
