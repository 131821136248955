//
// general.scss
//

html {
    position: relative;
    min-height: 100%;
}

body {
    overflow-x: hidden;
    font-family: 'Lato', sans-serif
}

// Fix event bubbling on iOS devices
@supports (-webkit-overflow-scrolling: touch) {
    body {
        cursor: pointer;
    }
}

.bg-black {
    background-color: #141414;
}

.center-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    color: #2ca9f2;
}

.edge-toast.toast {
    position: fixed;
    top: 85px;
    z-index: 9999;
    right: 24px;
    max-width: 450px;
    min-width: 350px;
    background: #fff;

    &.success {
        background: #d1e7dd;
        color: #0a3622;
    }
    &.danger {
        background: #f1aeb5;
        color: #58151c;
    }
    &.warning {
        background: #fff3cd;
        color: #664d03;
    }
    &.info {
        background: #cfe2ff;
        color: #052c65
    }
}

.toast-header {
    justify-content: space-between;
}

.btn-close {
    --bs-btn-close-color: #000;
    --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
    --bs-btn-close-opacity: 0.5;
    --bs-btn-close-hover-opacity: 0.75;
    --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    --bs-btn-close-focus-opacity: 1;
    --bs-btn-close-disabled-opacity: 0.25;
    --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: .25em .25em;
    color: var(--bs-btn-close-color);
    background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
    border: 0;
    border-radius: .375rem;
    opacity: var(--bs-btn-close-opacity);
}

.loading {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loading h2 {
    margin: 0;
}